<template>
  <div>
    <div class="dc-title">
      {{ $t("auCapitals.descriptionCapital.about") }}
    </div>
    <div class="dc-text">
      {{ isLarge ? description : descriptionSmall }}
    </div>
    <div
      v-if="showReadMore"
      class="dc-read-more"
      @click="showMore"
    >
      {{ $t("auCapitals.descriptionCapital.more") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DescriptionCapital",
  props: {
    description: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      descriptionSmall: "",
      showReadMore: false,
      isLarge: true
    };
  },
  mounted() {
    this.checkIsDescriptionLarge();
  },
  methods: {
    checkIsDescriptionLarge() {
      let index = this.description.indexOf("\r\n\r\n");
      index = this.description.indexOf("\r\n\r\n", index + 1);
      if (index !== -1) {
        this.descriptionSmall = this.description.slice(0, index);
        this.showReadMore = true;
        this.isLarge = false;
      }
    },
    showMore() {
      this.isLarge = true;
      this.showReadMore = false;
    }
  }
};
</script>

<style scoped lang="scss">
.dc-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
}
.dc-text {
  color: var(--color-50);
  white-space: pre-line;
}
.dc-read-more {
  margin-top: 15px;
  cursor: pointer;
  font-size: 15px;
  color: #2ABA7C;
  text-decoration: underline;
}
</style>