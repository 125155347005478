<template>
  <div class="loader skeleton skeleton-inner" />
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "100px"
    },
    height: {
      type: String,
      default: "16px"
    }
  }
};
</script>


<style scoped lang="scss">
@keyframes bgAnimate {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -150% 0;
  }
}

.skeleton {
  background: var(--card-color);
  background-size: 200% 100%;

  &-inner {
    animation: bgAnimate 2s linear infinite;

    background-image: linear-gradient(
      to right,
      var(--color-90) 0%,
      var(--color-95) 10%,
      var(--color-95) 20%,
      var(--color-90) 100%
    );
  }
}

.loader {
  display: flex;
  height: v-bind(height);
  width: v-bind(width);
  border-radius: 6px;
}
</style>