<template>
  <div>
    <AuBreadcrumb
      :pages="breadcrumb"
      :current-page-title="isAddNewsMode ? $t('router.pages.createNews') : ''"
    />
    <div
      v-if="showNewsTable"
      class="card projects"
    >
      <div class="header">
        <div>
          <div class="title">
            {{ $t("news.label") }}
          </div>
          <span class="explain">
            {{ $t("news.newsCount") }}:
            <span class="count">
              {{ blogPagesCount }}
            </span>
          </span>
        </div>
        <AuButton
          v-if="isIssuer"
          type="primary"
          @click="createNews"
        >
          <span class="bnt-text">{{ $t("news.addNewsButton") }}</span>
        </AuButton>
      </div>
      <ProgressLoader
        v-if="dataLoading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div v-else>
        <div v-if="haveNews">
          <AuTable
            :columns="columns"
            :items="formattedNews"
            :icons="null"
            @edit="editNews"
            @del="showDeleteDialog"
          >
            <template #image="{value}">
              <img
                class="image"
                :src="value"
                alt=""
              >
            </template>
            <template #action="{value}">
              <div
                v-click-outside="closeAllMenus"
                class="container-menu"
              >
                <AuIcon
                  icon="more"
                  as-button
                  @click="openMenu(value.index)"
                />
                <div
                  v-if="showMenu[value.index]"
                  class="context-menu"
                >
                  <AuButton
                    width="100%"
                    center
                    popup
                    border-color="none"
                    active-bg-color="none"
                    hovered-bg-color="none"
                    @click="editNews(value.id)"
                  >
                    {{ $t("counterparties.editAction") }}
                  </AuButton>
                  <AuButton
                    width="100%"
                    center
                    popup
                    border-color="none"
                    active-bg-color="none"
                    hovered-bg-color="none"
                    @click="showDeleteDialog(value.id)"
                  >
                    {{ $t("counterparties.deleteAction") }}
                  </AuButton>
                </div>
              </div>
            </template>
          </AuTable>

          <AuPagination
            v-if="blogPagesCount > 30"
            :total-items="blogPagesCount"
            @pageChanged="pageChanged"
          />
        </div>
      </div>
    </div>
    <RouterView />
    <AuModal
      v-model="deleteDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          {{ $t("news.deleteDialog.title") }}
        </span>
      </template>
      <template #body>
        <span>
          {{ $t("news.deleteDialog.subtitle") }}
        </span>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="modalClick"
            >
              {{ $t("news.deleteDialog.agree") }}
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="delCancel"
            >
              {{ $t("news.deleteDialog.cancel") }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";

import ProgressLoader from "@/loaders/progress-bar";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "News",
  components: { ProgressLoader },
  data() {
    return {
      deleteDialog: false,
      deleteId: null,
      totalItems: 40,
      showMenu: []
    };
  },

  computed: {
    ...mapGetters({
      blogPages: "news/blogPages",
      blogPagesCount: "news/blogPagesCount",
      dataLoading: "news/dataLoading"
    }),

    breadcrumb() {
      const ret = [{ key: 0, name: this.$t("router.pages.dashboard"), path: "/" }];
      if (!this.showNewsTable) {
        return [...ret, { key: 1, name: this.$t("router.pages.news"), path: "/news" }];
      }
      return ret;
    },

    haveNews() {
      return !_.isEmpty(this.blogPages);
    },

    formattedNews() {
      return _.map(this.blogPages, (blogPage, i) => ({
        image: blogPage.imageFile,
        title: blogPage.title || this.$t("news.responseTable.title"),
        isDraft: blogPage.isDraft ? this.$t("news.responseTable.statusDraft") : this.$t("news.responseTable.statusPublished"),
        action: {
          id: blogPage.id,
          index: i
        }
      }));
    },

    showNewsTable() {
      return !_.includes(this.$route.path, "edit-news");
    },

    isAddNewsMode() {
      return this.$route.params?.id === "new";
    },

    columns() {
      return [{ name: this.$t("news.firstColumn"), align: "start", slot: "image" },
        { name: this.$t("news.secondColumn"), align: "start" },
        { name: this.$t("news.thirdColumn") },
        { name: this.$t("news.fourthColumn"), slot: "action" }];
    },

    isIssuer() {
      return this.$auth.user().type == "issuer";
    }
  },

  async created() {
    await this.getBlogPages();
    this.closeAllMenus();
  },

  async beforeDestroy() {
    await this.setBlogPagesPage(1);
  },

  methods: {
    ...mapActions({
      removeBlogPage: "news/removeBlogPage",
      setBlogPagesPage: "news/setBlogPagesPage",
      getBlogPages: "news/getBlogPages"
    }),

    closeAllMenus() {
      this.showMenu = _.map(this.blogPages, () => false);
    },

    openMenu(index) {
      this.closeAllMenus();
      this.showMenu[index] = true;
    },

    async pageChanged(page) {
      this.setBlogPagesPage(page);
      await this.getBlogPages();
    },

    delCancel() {
      this.deleteDialog = false;
      this.deleteId = null;
    },

    async modalClick() {
      try {
        await this.removeBlogPage(this.deleteId); ///////////
        this.deleteId = null;
        this.deleteDialog = false;
        this.$toast.success(this.$t("toasts.success.newsDelete"));
        await this.getBlogPages();
      }
      catch (error) {
        this.$toast.error(this.$t("toasts.error.newsDelete"));
      }
    },

    showDeleteDialog(id) {
      this.deleteDialog = true;
      this.deleteId = id;
    },

    createNews() {
      this.$router.push({ name: "edit news", params: { id: "new" } });
    },

    editNews(id) {
      this.$router.push({ name: "edit news", params: { id: id } });
    }
  }
};
</script>

<style scoped lang="scss">
.projects{
  padding: 20px;
  font-family: 'Suisse Intl',serif;
  font-style: normal;
}
.header {
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
}
.title{
  font-size: 24px;
  line-height: 1.1em;
}
.count{
  font-weight: 500;
  font-size: 14px;
  color: var(--color-60);
}
.bnt-text{
  font-weight: 450;
  font-size: 12px;
}
.explain {
  font-weight: 450;
  font-size: 12px;
  color: var(--color-60);
}
.p-indent{
  margin-bottom: 30px;
}
.modal_head_text {
  font-family: 'Suisse Intl',serif;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}
.btn_indent{
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.btn-text{
  font-weight: 450;
  font-size: 12px;
}
.image {
  width: 80px;
  height: 80px;
  margin-right: 8px;
  border-radius: 7px;
  padding: 4px;
}
.container-menu{
  position: relative;
}
.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 128px;
  right: calc(50% - 128px / 2);
  background: var(--menu-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  z-index: 10;
  top: 30px;
}
</style>
