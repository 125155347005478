<template>
  <div>
    <AuBreadcrumb
      :parent-name="$t('router.pages.dashboard')"
    />
    <div
      class="referral-block card"
    >
      <div class="title">
        {{ $t("profileMenu.referralProgram") }}
      </div>
      <div class="referral-fields">
        <div class="referral-field-wrapper">
          <AuInput
            v-model="referralLink"
            :label="$t('referralProgram.userRefLabel')"
          />

          <AuButton
            icon="copy"
            height="44px"
            bordered
            @click="copyToClipboard"
          />
        </div>
        <div class="referral-source-field-wrapper">
          <AuInput
            v-model="refSource"
            type="Mask"
            :label="$t('referralProgram.refSourceLabel')"
            :placeholder="$t('referralProgram.refSourcePlaceholder')"
            :mask="/^[0xa-zA-Z]+$/i"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReferralProgram",

  data() {
    return {
      refSource: ""
    };
  },

  computed: {
    referralLink() {
      const host = window.location.origin;
      return this.refSource ? `${host}/sign?userRef=${this.$auth.user().id}&refSource=${this.refSource}` : `${host}/sign?userRef=${this.$auth.user().id}`;
    }
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.referralLink)
        .then(() => {
          this.$toast.success(this.$t("tokeninfo.copySuccess"));
        })
        .catch(() => {
          this.$toast.error(this.$t("tokeninfo.copyError"));
        });
    }
  }
};
</script>

<style scoped lang="scss">
  .card {
    &.referral-block {
      padding: 22px;

      .title {
        font-size: 24px;
        line-height: 1em;
        border-bottom: 1px solid var(--color-70);
        padding-bottom: 15px;
        margin-bottom: 20px;
      }

      .referral-field {
        &s {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        &-wrapper {
          display: flex;
          gap: 15px;
          align-items: flex-end;
        }
      }
    }
  }
</style>