<template>
  <div>
    <div class="fs22">
      {{ $t("incomeChart.title") }}
    </div>
    <div class="i-control">
      <div class="i-indicator">
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="isBalanceActive ? 'link' : ''"
          @click="selectGraph"
        >
          {{ $t("incomeChart.balance") }}
        </AuButton>
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="!isBalanceActive ? 'link' : ''"
          @click="selectGraph"
        >
          {{ $t("incomeChart.profit") }}
        </AuButton>
      </div>
      <div class="i-indicator">
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="activePeriod === 0 ? 'link' : ''"
          @click="activePeriod = 0;"
        >
          {{ $t("incomeChart.day") }}
        </AuButton>
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="activePeriod === 1 ? 'link' : ''"
          @click="activePeriod = 1;"
        >
          {{ $t("incomeChart.week") }}
        </AuButton>
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="activePeriod === 2 ? 'link' : ''"
          @click="activePeriod = 2;"
        >
          {{ $t("incomeChart.month") }}
        </AuButton>
      </div>
    </div>
    <Apexchart
      :series="series"
      :options="options"
      height="200px"
    />
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment";
import { formatDecimalStatic } from "@/help/decimalStatic";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "IncomeChart",
  data() {
    return {
      isBalanceActive: true,
      activePeriod: 0,
      balanceData: [],
      profitData: [],
      page: 1,
      itemsPerPage: 30,
      series: [{
        data: [2500, 2131, 2700, 1945, 1800, 1763],
        color: "#28E593",
        name: this.$t("incomeChart.balance")
      }],
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"]
    };
  },
  computed: {
    ...mapGetters({
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),

    options() {
      return {
        chart: {
          type: "area",
          toolbar: {
            show: false
          },
          parentHeightOffset: 0
        },
        stroke: {
          curve: "smooth"
        },
        markers: {
          size: 5
        },
        fill: {
          type: "gradient",
          colors: ["#28E593"],
          gradient: {
            opacityFrom: 0.7,
            opacityTo: 0.1
          }
        },
        tooltip: {
          enabled: true
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false,
          row: {
            colors: ["#28E5931A"],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: this.categories
        },
        yaxis: {
          labels: {
            formatter: (v, _) => {
              return `${v} ${this.defaultTokenMnemonic}`;
            }
          }
        }
      };
    },
    showFakeGraph() {
      return /fake/i.test(window.location.href);
    }
  },
  async mounted() {
    if (!this.showFakeGraph) {
      await this.getBriefcaseData();
    }
  },
  methods: {
    ...mapActions({
      getDailyStats: "getDailyStats"
    }),

    async getBriefcaseData() {
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage
      };
      try {
        const resp = await this.getDailyStats(params);
        this.categories = _.map(resp.data, item => moment(item?.createdAt).format("DD.MM.YYYY"));
        this.balanceData = _.map(resp.data, item => formatDecimalStatic({
          value: item?.briefcaseValue,
          decimal: 2
        }).replace("\xA0", "").replace(" ", ""));
        this.profitData = _.map(resp.data, item =>
          BigNumber(item?.dividendsValue).plus(item?.investingValue).toNumber());
        this.series[0].data = this.balanceData;
      }
      catch (e) {
        console.error(e);
      }
    },

    selectGraph() {
      this.isBalanceActive = !this.isBalanceActive;
      if (this.showFakeGraph) {
        this.createFakeData();
      }
      this.series[0].data = this.isBalanceActive ? this.balanceData : this.profitData;
      this.series[0].name = this.isBalanceActive ? this.$t("incomeChart.balance") : this.$t("incomeChart.profit");
    },

    createFakeData() {
      const initArray = this.isBalanceActive ?
        [2500, 2131, 2700, 1945, 1800, 1763] : [7, 10, 50, 40, 30, 60];
      const max = this.isBalanceActive ? 2000 : 10;
      this.series[0].data = _.map(initArray, el => {
        const delta = Math.floor(Math.random() * max);
        return Math.floor(Math.random() * 2) === 0 ? el + delta : el - delta;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.i-control {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.i-indicator {
  display: flex;
  gap: 10px;
}
</style>