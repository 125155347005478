<template>
  <div class="avatar">
    <img
      v-if="src"
      class="img"
      :src="src"
      alt="icon"
    >
    <div
      v-else
      class="d-flex align-items-center gap-2"
    >
      <div
        class="circle"
        :class="{'small': mode === 'small'}"
      >
        {{ name }}
      </div>
      <span v-if="showName">
        {{ fullName }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuAvatar",
  props: {
    src: {
      type: String,
      // default: "/img/Profile.png"
      default: ""
    },
    fName: {
      type: String,
      default: ""
    },
    iName: {
      type: String,
      default: ""
    },
    showName: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "normal"
    }
  },
  data() {
    return {};
  },
  computed: {
    theme() {
      return this.$store.getters.isLightTheme ? "light" : "dark";
    },
    name() {
      return (this.fName ?? " ")[0].toUpperCase() + (this.iName ?? " ")[0].toUpperCase();
    },
    fullName() {
      let fName = this.fName ?? " ";
      fName = fName[0].toUpperCase() + fName.slice(1);
      let iName = this.iName ?? " ";
      iName = iName[0].toUpperCase() + iName.slice(1);
      return fName + " " + iName;
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  .img {
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    object-fit: cover;
    width: 60px;
    height: 60px;

    &:hover {
      opacity: 0.7;
      transition: 0.2s ease-in-out;
    }
  }
  .no-img {
    border-radius: 50%;
    border: 1px solid var(--color-20);
    transition: 0.2s ease-in-out;
    object-fit: cover;
    padding: 20px;

    &:hover {
      opacity: 0.7;
      transition: 0.2s ease-in-out;
    }
  }
  .icon {
    font-size: 30px;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    background-color: var(--color-50);
    border: 1px solid var(--color-30);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;

    &:hover {
      opacity: 0.7;
      transition: 0.2s ease-in-out;
    }
  }
}
.circle {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: #279063;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
.small {
  width: 42px;
  height: 42px;
  font-size: 16px;
  background-color: silver;
}
</style>
