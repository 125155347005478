<template>
  <div>
    <div class="fs22 r-title">
      {{ $t("referral.title") }}
    </div>
    <ProgressBar
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div
      v-else
      class="r-full-size"
    >
      <div
        v-if="referral.length > 0"
        class="r-team"
      >
        <img
          class="icon"
          src="/img/dashboard/in-command.svg"
          alt=""
        >
        <span>
          {{ $t("referral.inTeam") }}
          <span class="count"> {{ totalItems }} </span>
        </span>
      </div>
      <div v-if="referral.length > 0">
        <AuTable
          :columns="columns"
          :items="items"
          :property-style-function="propertyStyleFunction"
        />
        <AuPagination
          v-if="totalItems > itemsPerPage"
          :total-items="totalItems"
          @pageChanged="changePage"
        />
      </div>
      <div
        v-else
        class="r-empty"
      >
        <img
          class="r-image"
          src="/img/dashboard/not_found_ref.svg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import ProgressBar from "@/components/progressBar/ProgressBar";
import { mapActions } from "vuex";

export default {
  name: "Referral",
  components: { ProgressBar },
  data() {
    return {
      referral: [],
      page: 1,
      itemsPerPage: 3,
      totalItems: 0,
      loading: true
    };
  },
  computed: {
    columns() {
      return [{ name: this.$t("referral.date"), align: "left", padding: "7px" },
        { name: this.$t("referral.email"), align: "left", padding: "7px" }];
    },

    items() {
      return _.map(this.referral, item => ({
        date: moment(item?.createdAt).format("DD.MM.YYYY"),
        email: item?.email
      }));
    }

  },
  async mounted() {
    await this.getReferralData();
  },
  methods: {
    ...mapActions({
      getReferrals: "getReferrals"
    }),

    async getReferralData() {
      this.loading = true;
      const resp = await this.getReferrals({
        page: this.page,
        itemsPerPage: this.itemsPerPage
      });
      this.referral = resp.data;
      this.totalItems = resp.totalItems;
      this.loading = false;
    },

    async changePage(actualPage) {
      this.page = actualPage;
      await this.getReferralData();
    },

    propertyStyleFunction() {
      return {
        "padding": "7px"
      };
    }
  }
};
</script>

<style scoped lang="scss">
.r-title {
  margin-bottom: 20px;
}
.r-full-size {
  height: 100%;
}
.r-empty {
  display: flex;
  height: content-box;
  justify-content: center;
  align-items: center;
}
.r-image {
  width: 180px;
  height: 194px;
}
.r-team {
  display: flex;
  align-items: end;
  gap: 8px;
  margin-bottom: 10px;

  .icon {
    width: 26px;
    height: 26px;
  }

  .count {
    font-weight: 500;
    color: #2ABA7C;
  }
}
</style>