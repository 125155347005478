<template>
  <div>
    <div class="fs22">
      {{ $t("NewsWidget.title") }}
    </div>
    <div class="news-container">
      <div
        v-for="(news, i) of newsArray"
        :key="i"
        :class="{n1: i === 0, n2: i === 1, n3: i === 2}"
      >
        <img
          class="news-image"
          :src="news.image"
          alt=""
        >
        <AuAvatar
          class="news-author"
          :f-name="news.fName"
          :i-name="news.iName"
          mode="small"
          show-name
        />
        <div class="news-title">
          <RouterLink
            class="news-link"
            :to="news.link"
          >
            {{ news.title }}
          </RouterLink>
        </div>
        <div
          class="news-text"
          :class="{'news-text-black': !isLightTheme}"
        >
          {{ news.subtitle }}
        </div>
        <div class="news-divider" />
        <div
          class="news-text"
          :class="{'news-text-black': !isLightTheme}"
        >
          {{ news.date }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import formatDate from "@/help/dateSupport";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "News",
  computed: {
    ...mapGetters({
      blogPages: "blogPages",
      isLightTheme: "isLightTheme",
      language: "language"
    }),

    newsArray() {
      let bufArr = this.blogPages?.data ?? [];
      bufArr.length = bufArr.length < 4 ? bufArr.length : 3;
      return _.map(bufArr, item => ({
        image: item.imageFile,
        title: item.title,
        subtitle: item.subtitle,
        link: this.getLink(item),
        date: formatDate(item.dateForNews, this.language),
        fName: item?.author.fName,
        iName: item?.author.iName
      }));
    }
  },
  async mounted() {
    await this.getBlogPages();
  },
  methods: {
    ...mapActions({
      getBlogPages: "getBlogPages"
    }),

    getLink(item) {
      const iriCapital = item.auCapital["@id"] ?? "";
      const id = iriCapital.slice(iriCapital.lastIndexOf("/") + 1);
      return `/project-card/${id}`;
    }
  }
};
</script>

<style scoped lang="scss">
.news-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:  "n1 n2 n3";
  gap: 20px;
  margin-top: 20px;
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:  "n1 n2";
  }
  @media screen  and (max-width: 600px){
    grid-template-columns: 1fr;
    grid-template-areas:  "n1";
  }
}
.n1 {
  grid-area: n1;
}
.n2 {
  grid-area: n2;
  @media screen  and (max-width: 600px){
    display: none;
  }
}
.n3 {
  grid-area: n3;
  @media screen  and (max-width: 1500px){
    display: none;
  }
}
.news-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  margin-bottom: 10px;
}
.news-author {
  margin-bottom: 10px;
}
.news-title {
  margin-bottom: 10px;
  .news-link {
    color: #279063;
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
  }
}
.news-divider {
  width: 100%;
  height: 3px;
  border: 1px solid #F9F9F9;
  background-color: #F9F9F9;
  margin: 8px 0 10px 0;
}
.news-text {
  color: #33333399;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.news-text-black {
  color: #CCCCCC99;
}
</style>