import BigNumber from "bignumber.js";

export function formatDecimalStatic({
  value = "",
  decimal = 0,
  indent = 2,
  price = 1
} = {}) {
  // Форматирование значения
  const getFormatedValue = () => {
    const type = typeof value;
    let val = value ? value.toString().replace(",", ".") : 0;

    if (decimal === 0) {
      return val;
    }

    let result = "";
    if (type === "number") {
      result = BigNumber(val).toFixed(decimal);
    }
    else {
      result = (BigNumber(val)
        .dividedBy(BigNumber(10).pow(decimal))
        .toFixed(decimal)) * price;
    }

    return BigNumber(result).toString();
  };

  // Получение отображаемого значения
  const getDisplayValue = () => {
    let val = getFormatedValue();
    if (indent && val && val.includes(".")) {
      val = Number(val).toLocaleString("ru", {
        minimumFractionDigits: indent,
        maximumFractionDigits: indent,
        roundingMode: "trunc"
      });
    }
    else {
      val = Number(val).toLocaleString("ru", {
        maximumFractionDigits: decimal,
        roundingMode: "trunc"
      });
    }
    return val.replace(",", ".");
  };

  return getDisplayValue();
}