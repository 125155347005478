<template>
  <div
    v-if="isPanelsDontNeed"
    id="wrapper"
    class="wrapper"
    :style="cssVars"
  >
    <RouterView />
    <ChatPanel
      v-if="isChatVisible"
      :name="$t('chat.title')"
      :visible="isChatVisible"
      @close="setChatVisible(false)"
    />
  </div>

  <GlobalLoader v-else-if="loading" />

  <div
    v-else
    :style="cssVars"
    class="app"
  >
    <GlobalError
      v-if="errorText"
      :header="$t('globalError.header')"
      :body="errorText"
      width="500px"
    />

    <div class="app__left">
      <LeftPanel />
    </div>

    <div class="app__right">
      <TopPanel />
      <RouterView />
      <ChatPanel
        v-if="isChatVisible"
        :name="$t('chat.title')"
        :visible="isChatVisible"
        @close="setChatVisible(false)"
      />
    </div>
  </div>
</template>

<script>
import ChatPanel from "@/components/ChatPanel";
import GlobalError from "@/components/GlobalError.vue";
import GlobalLoader from "@/components/GlobalLoader.vue";
import LeftPanel from "@/panels/LeftPanel.vue";
import localStorageController from "@/help/localStorageController";
import TopPanel from "@/panels/TopPanel.vue";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/vue";
import { mapActions, mapGetters } from "vuex";

import _ from "lodash";

export default {
  name: "App",

  components: {
    ChatPanel,
    LeftPanel,
    TopPanel,
    GlobalLoader,
    GlobalError
  },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      isChatVisible: "isChatVisible",
      tokens: "tokens",
      tokensGold: "tokensGold",
      defaultTokenId: "defaultToken/defaultTokenId",
      defaultAutMnemonic: "defaultToken/defaultAutMnemonic",
      profile: "profile/profile",
      socketStatus: "socket/socketStatus",
      socketHasPreviousConnection: "socket/socketHasPreviousConnection"
    }),

    errorText() {
      if (!this.defaultTokenId) {
        return this.$t("globalError.defaultTokenBody");
      }
      else if (!this.defaultAutMnemonic) {
        return this.$t("globalError.defaultAutMnemonicBody");
      }

      return null;
    },

    isOuter() {
      return this.$route.meta.outer == true;
    },

    isPanelsDontNeed() {
      return (
        !this.$auth.user() ||
          this.$route?.name === "logout" ||
          this.$route?.name === "login" ||
          this.$route?.name === "page not found" ||
          this.$route?.name === "EmailVerify" ||
          this.$route?.meta.outer === true
      );
    },

    user() {
      return this.$auth.user();
    },

    cssVars() {
      return {
        "--main-color": this.isLightTheme ? "#F2F1F7" : "#121212",
        "--menu-color": this.isLightTheme ? "#FFFFFF" : "#1c1c1e",
        "--menu-color-select": this.isLightTheme ? "#c3f6e0" : "#575757",
        "--border-color": this.isLightTheme ? "#959da533" : "#404040",
        "--card-color": this.isLightTheme ? "#FFFFFF" : "#1C1C1E",
        "--text-color": this.isLightTheme ? "#080808" : "#FFFFFF",

        "--tester-color": this.isLightTheme ? "repeating-linear-gradient(45deg, rgb(255 255 255 / 5%), rgb(255 255 255 / 10%) 14px, rgb(98 98 98 / 20%) 15px, rgb(114 114 114 / 15%) 40px)" : "repeating-linear-gradient(45deg, rgba(98 98 98 / 20%), rgba(114 114 114 / 15%) 14px, rgba(255 255 255 / 20%) 15px, rgba(255 255 255 / 10%) 40px)",

        // Buttons
        "--btn-dg-color-primary": this.isLightTheme ? "#2aba7c" : "#2aba7c",
        "--btn-dg-color-dis": this.isLightTheme ? "#8adcba" : "#3b8d6b",
        "--btn-ft-color-dis": this.isLightTheme ? "#FFFFFF" : "#cbcbcb",
        "--btn-border-color-def": this.isLightTheme ? "#cbcbcb" : "#858585",

        "--scrollbar-color": this.isLightTheme ? "#b0b7c3" : "#393939",
        color: this.isLightTheme ? "#080808" : "#FFFFFF",

        "--text-filter": this.isLightTheme ? "brightness(0) saturate(100%) invert(0%) sepia(28%) saturate(4611%) hue-rotate(47deg) brightness(92%) contrast(94%)" : "",

        "--color-00": this.isLightTheme ? "#080808" : "#FFFFFF",
        "--color-10": "#E6E6E6",
        "--color-20": this.isLightTheme ? "#080808CC" : "#CDCDCD",
        "--color-30": "#B4B4B4",
        "--color-40": this.isLightTheme ? "#080808CC" : "#9B9B9B",
        "--color-50": this.isLightTheme ? "#08080866" : "#828282",
        "--color-60": this.isLightTheme ? "#08080899" : "#696969",
        "--color-70": this.isLightTheme ? "#0808081A" : "#505050",
        "--color-80": this.isLightTheme ? "#0808084D" : "#373737",
        "--color-90": this.isLightTheme ? "#e3e2e8" : "#1E1E1E",
        "--color-95": this.isLightTheme ? "#F2F1F7" : "#121212",
        "--color-100": "#050505",

        "--sep-line-color": this.isLightTheme ? "#F9F9F9" : "#151515"
      };
    },

    defaultToken() {
      return _.find(this.tokens, { mnemonicEng: process.env.VUE_APP_TOKEN_DEFAULT });
    },

    themeDefault() {
      return process.env.VUE_APP_THEME_DEFAULT == "light";
    }
  },

  watch: {
    socketStatus(status) {
      if (status) {
        if (this.socketHasPreviousConnection) {
          this.$toast.success("WebSocket re-connected");
        }
        this.setSocketHasPreviousConnection(true);
      }
      else {
        this.getUserBalanceInterval();
        console.error("WebSocket connection error");
      }
    },

    user: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue) {
          if (newValue?.id !== oldValue?.id) {
            this.loading = true;
            await this.getAuTokens({ type: "utility,fiat" });

            this.setDefaultToken({
              id: newValue.defaultTokenId || this.defaultToken?.id,
              mnemonic: newValue.defaultTokenMnemonic || this.defaultToken?.mnemonicEng
            });

            await this.getCurrencyRate();
            await this.getBalances(this.defaultTokenId);
            await this.getAuOwners();
            await this.getEthAddresses();
            await this.getTonAddresses();
            await this.getSmartContractsTypes();
            await this.getExchangeBalances(newValue?.id);
            await this.getProfile(newValue?.id);
            if (!this.profile?.carrotId) {
              await this.setUserCarrotId(newValue?.id);
            }

            try {
              if (window.carrotquest && process.env.VUE_APP_CARROT_ENABLED === "true") {
                window.carrotquest.identify([
                  { op: "update_or_create", key: "$email", value: this.profile.email }
                ]);
              }
            }
            catch (error) {
              console.error(error);
            }

            if (newValue.type === "issuer") {
              await this.getHydraRequestToIssue({ page: 1 });
              await this.getHydraCompanies({ page: 1 });
            }

            this.$auth.remember(this.profile.email);

            this.loading = false;
            this.setCarrotIframeColor();
            this.windowResize();
          }
        }

        if (this.isOuter) {
          this.setIsLightTheme(true);
        }
        else if (!localStorageController.getUserMadeChoice()) {
          this.setIsLightTheme(this.themeDefault);
        }
        else {
          let isLightTheme = localStorageController.getIsLightTheme();
          this.setIsLightTheme(!(isLightTheme === "false"));
        }
        if (!process.env.VUE_APP_SUPPORTED_LOCALES.includes(",")) {
          this.setLanguage(process.env.VUE_APP_SUPPORTED_LOCALES);
        }
      }
    },

    isLightTheme() {
      this.setCarrotIframeColor();
    }
  },

  async created() {
    this.initWeb3Modal();
  },

  mounted() {
    window.addEventListener("resize", this.windowResize);
  },

  methods: {
    ...mapActions({
      getAuTokens: "getAuTokens",
      getAuOwners: "getAuOwners",
      getCurrencyRate: "getCurrencyRate",
      getBalances: "getBalances",
      getEthAddresses: "getEthAddresses",
      getTonAddresses: "getTonAddresses",
      setDefaultToken: "defaultToken/setDefaultToken",
      getExchangeBalances: "getExchangeBalances",
      setChatVisible: "setChatVisible",
      sendNotify: "sendNotify",
      getHydraRequestToIssue: "getHydraRequestToIssue",
      getHydraCompanies: "getHydraCompanies",
      setLanguage: "setLanguage",
      getProfile: "profile/getProfile",
      patchProfile: "profile/patchProfile",
      setSocketHasPreviousConnection: "socket/setSocketHasPreviousConnection",
      setIsLightTheme: "setIsLightTheme",
      getSmartContractsTypes: "getSmartContractsTypes"
    }),

    setCarrotIframeColor() {
      const iframe = document.querySelector("#carrot-frame-bumperCookies");
      if (iframe) {
        iframe.contentDocument.activeElement.style = "background: " + (this.isLightTheme ? "#FFFFFF" : "#1C1C1E");
      }
    },

    getUserBalanceInterval() {
      setInterval(async () => {
        if (this.user) {
          await this.getBalances(this.defaultTokenId);
          await this.getAuOwners();
        }
      }, 30000);
    },

    windowResize() {
      let body = document.body,
        html = document.documentElement;

      let height = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);

      window.parent.postMessage({ height: height + "px" }, "*");
    },

    initWeb3Modal() {
      const projectId = "8a93afa8fa43d9dcf5b589ac25a82eca";

      const ethereum = {
        chainId: 1,
        name: "Ethereum Mainnet",
        currency: "ETH",
        rpcUrl: "https://cloudflare-eth.com",
        explorerUrl: "https://etherscan.io"
      };

      const avax = {
        chainId: 43114,
        name: "Avalanche C-Chain",
        currency: "AVAX",
        rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
        explorerUrl: "https://snowtrace.io"
      };

      const bsc = {
        chainId: 56,
        name: "BNB Smart Chain Mainnet",
        currency: "BNB",
        rpcUrl: "https://bsc-dataseed.binance.org/",
        explorerUrl: "https://bscscan.com/"
      };

      const metadata = {
        name: "Autentic",
        description: "Autentic",
        url: "https://aut.finance/",
        icons: ["https://aut.finance/logo.png"]
      };

      createWeb3Modal({
        ethersConfig: defaultConfig({ metadata }),
        chains: [bsc, avax, ethereum],
        projectId,
        enableAnalytics: true,
        featuredWalletIds: window?.ethereum?.isMetaMask ?
          [] : ["c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"]
      });
    },

    async setUserCarrotId(id) {
      const carrotId = this.$cookies.get("carrotquest_uid");
      if (carrotId) {
        try {
          await this.patchProfile({
            id: id,
            params: { carrotId: carrotId }
          });
        }
        catch (e) {
          console.error(e);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.app {
  display: flex;
  width: 100%;
  overflow: hidden;
  background: var(--main-color);

  &__right {
    width: 100%;
    overflow: hidden;
  }

  &__left {
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}

.wrapper {
  width: 100%;
}
.c-toast-container {
  margin-right: 8px;
}

// Style AuModal
.modal_head_text {
  font-family: 'Suisse Intl',serif;
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  border-bottom: 1px solid var(--color-70);
  margin-bottom: 25px;
  padding-bottom: 5px;
  width: 100%;
  margin-top: -5px;
}
.modal_button {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}
.modal_button_margin {
  margin-right: 10px;
}
</style>
