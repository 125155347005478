<template>
  <div>
    <div class="fs22">
      {{ $t("votingWidget.title") }}
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div v-else>
      <Apexchart
        v-if="topVoting.length > 0"
        :options="options"
        :series="topVoting"
        :height="height"
        @click="goToProject"
      />
      <div
        v-else
        class="v-empty"
      >
        <img
          class="v-image"
          src="/img/dashboard/not_found_voting.svg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Voting",
  components: { ProgressLoader },
  data() {
    return {
      maxX: 100,
      topVoting: [],
      barCount: 0,
      barHeight: 15,
      barIndent: 15,
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    height() {
      return 50 + (this.barCount * this.barHeight) + (this.barCount * 15) + "px";
    },

    options() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: `${this.barHeight}px`
          }
        },
        tooltip: {
          intersect: false,
          theme: this.isLightTheme ? "light" : "dark",
          custom: this.drawTooltip,
          style: {
            fontFamily: "Suisse Intl"
          },
          marker: {
            show: false
          }
        },
        dataLabels: {
          formatter: (v, _) => {
            return `${v}%`;
          }
        },
        xaxis: {
          type: "category",
          min: 0,
          max: this.maxX,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: "Suisse Intl",
              fontSize: "14px",
              colors: this.isLightTheme ? "black" : "white",
              cssClass: "votes-clickable"
            }
          }
        }
      };
    }
  },
  async created() {
    await this.calcTop();
  },
  methods: {
    ...mapActions({
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination"
    }),

    drawTooltip(params) {
      const currentData = params.w.config.series[0].data[params.dataPointIndex];
      const quorum = currentData.quorum;
      const mnemonic = currentData.mnemonic;
      const tokenAchievement = currentData.y;
      const currentCap = BigNumber(quorum).multipliedBy(tokenAchievement).dividedBy(100).toFixed(2);
      let ret = "<div style='border-radius: 10px;padding: 20px;'>";
      ret += `<div>Current cap: ${currentCap} ${mnemonic}</div>`;
      for (const item of currentData.goals) {
        const value = item.value < 0 ? `${-item.value}%` :
          BigNumber(quorum).multipliedBy(item.value).dividedBy(100).toFixed(2) + " " + mnemonic;
        ret += `<div>${item.name} ${value}</div>`;
      }
      return `${ret}</div>`;
    },

    goToProject(event, noUse, graphData) {
      let vote = null;
      if (graphData.dataPointIndex === -1) {
        vote = _.find(item => item.x === event.target.innerHTML);
      }
      else {
        vote = this.topVoting[0]?.data[graphData.dataPointIndex];
      }
      if (vote) {
        this.$router.push({ name: "project card", params: { id: vote.id } });
      }
    },

    getQuorum(project) {
      return BigNumber(project?.minCap ? project.minCap : project.maxCap)
        .dividedBy(BigNumber(10).pow(project.tokenQuote.decimals)).toFixed(2);
    },

    getPercent(project) {
      const value = BigNumber(project.currentCap);
      const oneHundredPercent = BigNumber(project?.minCap ? project.minCap : project.maxCap);
      return value.multipliedBy(100).dividedBy(oneHundredPercent).toFixed(2);
    },

    getGoals(project) {
      const ret = [];
      let quorum = 100;
      let hardCapName = "Hard cap (quorum):";

      ret.push({
        name: "Quorum achievement:",
        strokeWidth: -1,
        value: -this.getPercent(project)
      });
      if (project?.minCap) {
        quorum = BigNumber(project.maxCap).multipliedBy(100).dividedBy(project.minCap).toFixed(2);
        hardCapName = "Hard cap:";
        ret.push({
          name: "Soft cap (quorum):",
          strokeWidth: -1,
          value: 100
        });
      }
      ret.push({
        name: hardCapName,
        strokeWidth: -1,
        value: quorum
      });
      return ret;
    },

    async calcTop() {
      this.loading = true;
      const params = {
        page: 1,
        itemsPerPage: 100,
        projectType: "voting",
        status: "open"
      };
      try {
        const resp = await this.getAllAuCapitalsPagination(params);
        if (resp.data.length === 0) {
          this.loading = false;
          return;
        }
        // сортировка пока на фронте, но как будут фильтры бэка
        // все это будет переделано
        const sorted = resp.data.sort((a, b) => {
          return this.getPercent(b) - this.getPercent(a);
        });
        this.barCount = sorted.length;
        this.topVoting = [{
          data: _.map(sorted, item => ({
            x: item.tokenBase.nameEng,
            y: this.getPercent(item),
            id: item.id,
            quorum: this.getQuorum(item),
            mnemonic: item.tokenQuote.mnemonicEng,
            goals: this.getGoals(item)
          })),
          name: "Current cap",
          color: "#28E593"
        }];
        this.maxX = Number(this.topVoting[0].data[0].y);
      }
      catch (e) {
        console.error(e);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.v-empty {
  display: flex;
  justify-content: center;
}
.v-image {
  width: 180px;
  height: 194px;
}
</style>