<template>
  <div class="position-relative">
    <div class="ic-tags-place">
      <div class="ic-tag-info">
        <div
          class="ic-tag ic-status"
          :style="style"
        >
          <AuIcon :icon="statusIcon" /> <span>{{ status }}</span>
        </div>
        <div
          v-if="isVoting"
          class="ic-tag ic-vote"
        >
          <AuIcon icon="vote" /> <span>Voting</span>
        </div>
      </div>
      <div class="ic-section">
        {{ tag }}
      </div>
    </div>
    <img
      v-if="images.length === 1 && icon"
      class="ic-image"
      :src="icon"
      :alt="name"
    >
    <Swiper
      v-else-if="images.length > 1"
      :navigation="true"
      :hash-navigation="{
        watchState: true,
      }"
      :modules="modules"
    >
      <SwiperSlide
        v-for="(file, index) in images"
        :key="index"
      >
        <ProjectCardEmbeddedImage
          v-if="file.isVideoEmbedded"
          :embedded-code="file.embeddedCode"
          :src="file.thumb"
          :alt="file.nameOriginal"
          target-class=".ic-image"
          main-class=".main-panel"
        />

        <img
          v-else
          class="ic-image"
          :src="file.thumb"
          :alt="file.nameOriginal"
        >
      </SwiperSlide>
    </Swiper>

    <div
      v-else
      class="ic-image ic-no-image"
    >
      <img
        src="/img/project-card/no-photo.svg"
        :alt="name"
      >
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ProjectCardEmbeddedImage from "@/components/capital/ProjectCardEmbeddedImage";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
export default {
  name: "ImageCapital",
  components: { ProjectCardEmbeddedImage, Swiper, SwiperSlide },
  props: {
    icon: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    statusIcon: {
      type: String,
      default: ""
    },
    statusColor: {
      type: String,
      default: ""
    },
    tag: {
      type: String,
      default: ""
    },
    projectType: {
      type: String,
      default: ""
    },
    files: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modules: [Navigation]
    };
  },
  computed: {
    style() {
      return {
        "--status-color": this.statusColor
      };
    },

    images() {
      const images = _.filter(this.files, file => file.type == "image" || file.isVideoEmbedded);

      if (this.icon) {
        return [{ thumb: this.icon, nameOriginal: this.name }, ...images];
      }

      return images;
    },

    isVoting() {
      return this.projectType === "voting";
    }
  }
};
</script>

<style scoped lang="scss">
.ic-image {
  width: 100%;
  max-height: 400px;
  border-radius: 20px;
  object-fit: cover;
}
.ic-tags-place {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
}
.ic-tag-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ic-tag {
  display: flex;
  gap: 5px;
  color: white;
  padding: 12px;
  background-color: var(--status-color);
  border-radius: 12px;
  min-width: 90px;
  width: min-content;
  height: 45px;

  span {
    white-space: pre;
  }
}
.ic-status {
  background-color: var(--status-color);
}
.ic-vote {
  background-color: #D29C1E;
}
.ic-section {
  border-radius: 12px;
  color: black;
  background-color: white;
  padding: 12px;
  height: min-content;
}
.ic-no-image {
  background: var(--color-10);
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>