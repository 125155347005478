<template>
  <div>
    <div class="a-title">
      {{ $t("auCapitals.address.title") }}
    </div>
    <iframe
      :src="url"
      width="100%"
      height="384"
      style="border:0;border-radius: 20px;"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    />
  </div>
</template>

<script>
export default {
  name: "Address",
  props: {
    address: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      apiKey: "AIzaSyBZlJlLPBHmdWpaje4oCiav5M9ngQ36CMg"
    };
  },
  computed: {
    url() {
      const adr = encodeURI(this.address);
      return `https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=${adr}`;
    }
  }
};
</script>

<style scoped>
.a-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
</style>