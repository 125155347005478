<template>
  <div>
    <div class="ac-header">
      <img
        src="/img/capital/author.png"
        alt="author"
      >
      <span class="ac-title">{{ company }}</span>
    </div>
    <div class="ac-content">
      <AuAvatar
        :f-name="user.fName"
        :i-name="user.iName"
      />
      <div class="content">
        <span class="ac-title">{{ name }}</span>
        <span class="ac-text">{{ role }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AuthorCapital",
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    company: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      language: "language"
    }),

    name() {
      let fName = this.user.fName ?? "  ";
      fName = fName[0].toUpperCase() + fName.slice(1);
      let iName = this.user?.iName ?? " ";
      iName = iName[0].toUpperCase() + iName.slice(1);
      return `${fName} ${iName}`;
    },

    role() {
      return this.language === "en" ? "Company president" : "Президент компании";
    }
  }
};
</script>

<style scoped lang="scss">
.ac-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid lightgrey;
}
.ac-title {
    font-size: 16px;
    font-weight: 500;
}
.ac-text {
  color: var(--color-50);
}
.ac-content {
  display: flex;
  gap: 15px;

  .avatar {
    width: 76px;
    height: 76px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>