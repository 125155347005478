<template>
  <div>
    <div class="i-title">
      {{ $t("auCapitals.info.title") }}
    </div>
    <div class="i-list">
      <div
        v-for="(record, index) of info"
        :key="index"
        class="record"
      >
        <span class="label">
          {{ record.title }}
        </span>
        <div class="sep" />
        <div class="value">
          <span class="text">
            {{ record.value }}
          </span>
          <img
            v-if="record.checkbox > 0"
            :src="record.checkbox === 2 ? '/img/project-card/success-mark.svg' : '/img/project-card/no-mark.svg'"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoCapital",
  props: {
    id: {
      type: String,
      default: ""
    },
    country: {
      type: String,
      default: ""
    },
    company: {
      type: String,
      default: ""
    },
    dateStart: {
      type: String,
      default: ""
    },
    dateEnd: {
      type: String,
      default: ""
    },
    profitability: {
      type: String,
      default: ""
    }
  },
  computed: {
    info() {
      return [
        { title: this.$t("auCapitals.projectCard.information.country"), value: this.countryInfo, checkbox: 0 },
        { title: this.$t("auCapitals.projectCard.information.dividends"), value: "Yes", checkbox: 2 },
        { title: this.$t("auCapitals.projectCard.information.SPV"), value: this.companyInfo, checkbox: 0 },
        { title: this.$t("auCapitals.projectCard.information.equity"), value: "Yes", checkbox: 2 },
        { title: this.$t("auCapitals.projectCard.information.openDate"), value: this.dateStart, checkbox: 0 },
        { title: this.$t("auCapitals.projectCard.information.votingRight"), value: "No", checkbox: 1 },
        { title: this.$t("auCapitals.projectCard.information.closeDate"), value: this.dateEnd, checkbox: 0 },
        { title: this.$t("auCapitals.projectCard.information.specialConditions"), value: "No", checkbox: 1 }
      ];
    },

    countryInfo() {
      return this.country.length > 0 ? this.country : this.$t("auCapitals.projectCard.notSet");
    },

    companyInfo() {
      return this.company.length > 0 ? this.company : this.$t("auCapitals.projectCard.notSet");
    }
  }
};
</script>

<style scoped lang="scss">
.i-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
}
.i-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 25px;
  margin-bottom: 15px;

  .record {
    display: flex;
    align-items: end;
    gap: 5px;

    .label {
      font-size: 12px;
      color: var(--color-50);
    }

    .value {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .text {
      font-size: 12px;
      color: var(--text-color);
    }

    .sep {
      height: 2px;
      flex: 1;
      border-bottom: 1px dashed var(--color-50);
      margin-bottom: 4px;
      opacity: 0.5;
    }
  }
}
</style>