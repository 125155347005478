<template>
  <ProgressLoader
    v-if="breadcrumbLoading"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />
  <div v-else>
    <AuBreadcrumb
      :pages="breadcrumb"
    />
    <div class="card">
      <div class="header">
        <div class="title">
          {{ $t("auCapitals.projectInvestors.title") }}
        </div>
        <div class="title">
          <span class="explain">
            {{ $t("auCapitals.capitalHistory.project") }}
            <span class="data">{{ currentCapital?.tokenBase?.nameEng }}
            </span>
          </span>
        </div>
      </div>
      <AuTable
        class="projects-table"
        :columns="columns"
        :loading="dataLoading"
        :items="items"
      />
      <AuPagination
        v-if="investorsTotal > itemsPerPage"
        :total-items="investorsTotal"
        @pageChanged="changePage"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectInvestors",

  components: {
    ProgressLoader
  },

  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      currentCapital: [],
      columns: [
        { name: `${this.$t("projectInvestors.lastName")}`, align: "start" },
        { name: `${this.$t("projectInvestors.name")}`, align: "center" },
        { name: `${this.$t("projectInvestors.email")}`, align: "center" },
        { name: `${this.$t("projectInvestors.amount")}`, align: "center" }
      ],
      breadcrumbLoading: true
    };
  },
  computed: {
    ...mapGetters({
      investors: "capitals/investors",
      investorsTotal: "capitals/investorsTotal",
      dataLoading: "capitals/dataLoading"
    }),

    breadcrumb() {
      const breadcrumb = [
        { key: 0, name: this.$t("router.pages.dashboard"), path: "/" },
        { key: 1, name: this.$t("projects.label"), path: "/my-projects" }
      ];
      if (this.projectName) {
        breadcrumb.push({ key: breadcrumb?.length, name: this.projectName, path: `/project-card/${this.id}` });
      }
      return breadcrumb;
    },

    projectName() {
      return this.currentCapital?.tokenBase?.nameEng || null;
    },

    items() {
      return _.map(this.investors, item => ({
        iName: item.user.iName,
        fName: item.user.fName,
        email: item.user.email,
        value: item.value
      }));
    }
  },
  async mounted() {
    if (this.$auth.user().type != "issuer") {
      this.$router.push({ name: "base" });
    }
    await this.getItems();
    this.breadcrumbLoading = false;
  },
  methods: {
    ...mapActions({
      getCapitalInvestor: "capitals/getCapitalInvestor",
      getAuCapitals: "capitals/getAuCapitals"
    }),

    async getItems() {
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        auCapitalId: this.id,
        sortBy: "value",
        direction: "desc"
      };
      this.currentCapital = await this.getAuCapitals(this.id);
      await this.getCapitalInvestor(params);
    },

    async changePage(page) {
      this.page = page;
      await this.getItems();
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 100%;
  padding: 20px 22px 22px 20px;
}
.header {
  margin-bottom: 20px;
  .title {
    font-size: 24px;
    color: var(--text-color);
    line-height: 1.1;
  }
  .explain {
    font-weight: 450;
    font-size: 12px;
    color: var(--color-60);
    .data {
      font-weight: 500;
      font-size: 14px;
      color: var(--color-60);
    }
  }
}
.projects-table {
  margin-bottom: 0;
}

</style>