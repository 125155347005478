<template>
  <div>
    <div class="d-title">
      {{ $t("auCapitals.documents.title") }}
    </div>
    <div
      v-if="documents.length > 0"
      class="d-container"
    >
      <div
        v-for="(file, index) in documents"
        :key="index"
      >
        <a
          :href="file.name"
          target="_blank"
          class="doc"
        >
          <img
            src="/img/project-card/file-icon.svg"
            alt="file-icon"
          >
          <span>
            {{ file.nameOriginal }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "Documents",
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    documents() {
      return _.filter(this.files, file => file.type != "image" && !file.isVideoEmbedded);
    }
  }
};
</script>

<style scoped lang="scss">
.d-title {
  font-size: 16px;
  font-weight: 500;
}
.d-container {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  .doc {
    display: flex;
    align-items: start;
    gap: 5px;

    span {
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color);
    }
  }
}
</style>