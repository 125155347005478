<template>
  <div>
    <div class="rss-header">
      <span class="fs22">{{ title }}</span>
      <a
        :href="link"
        target="_blank"
      >
        <img
          class="rss-icon"
          src="/img/dashboard/rss.svg"
          alt=""
        >
      </a>
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div v-else>
      <div
        v-if="empty"
        class="rss-title"
      >
        {{ $t("Rss.empty") }}
      </div>
      <div
        v-for="(rss, i) of rssNews"
        v-else
        :key="i"
        class="rss-news"
      >
        <div class="rss-title">
          {{ rss.title }}
        </div>
        <div
          class="rss-text"
          :class="{'rss-text-black': !isLightTheme}"
          v-html="rss.description"
        />
        <div
          class="rss-text"
          :class="{'rss-text-black': !isLightTheme}"
        >
          {{ rss.date }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import formatDate from "@/help/dateSupport";
import ProgressLoader from "@/loaders/progress-bar";
import { mapGetters } from "vuex";
export default {
  name: "Rss",
  components: { ProgressLoader },
  data() {
    return {
      loading: true,
      empty: false,
      rssNews: []
    };
  },
  computed: {
    ...mapGetters({
      language: "language",
      isLightTheme: "isLightTheme"
    }),
    title() {
      return process.env.VUE_APP_TITLE;
    },
    link() {
      return this.language === "en" ? process.env.VUE_APP_RSS_EN_URL : process.env.VUE_APP_RSS_RU_URL;
    }
  },
  async mounted() {
    await this.loadAndParse();
  },
  methods: {
    async loadAndParse() {
      this.loading = true;
      try {
        const url = this.language === "en" ? process.env.VUE_APP_RSS_EN_URL : process.env.VUE_APP_RSS_RU_URL;
        const resp = await fetch(url);
        const rssText = await resp.text();
        const dom = new DOMParser().parseFromString(rssText, "text/xml");
        this.rssNews = _.map(Array.from(dom.querySelectorAll("item")), el => ({
          title: el.querySelector("title").textContent,
          description: el.querySelector("description").textContent,
          date: formatDate(el.querySelector("pubDate").textContent, this.language)
        }));
        this.rssNews.length = this.rssNews.length < 3 ? this.rssNews.length : 2;
        this.empty = this.rssNews.length === 0;
      }
      catch (e) {
        this.empty = true;
        console.error(e);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.rss-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rss-icon {
  width: 20px;
  height: 16px;
}
.rss-news {
  padding-bottom: 15px;
  border-bottom: 1px dashed #9B9B9B;
  &:last-child {
    border-bottom: none;
  }
}
.rss-title {
  font-size: 14px;
  font-weight: 500;
  padding-top: 15px;
  margin-bottom: 8px;
}
.rss-text {
  font-size: 12px;
  font-weight: 500;
  color: #33333399;
}
.rss-text-black {
  color: #CCCCCC99;
}
</style>