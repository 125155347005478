<template>
  <div class="news card">
    <div class="d-flex justify-content-between">
      <div class="head_text">
        {{ label }}
      </div>
      <AuButton
        @click="goBack"
      >
        {{ $t("news.form.cancelButton") }}
      </AuButton>
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div v-else>
      <div class="nf-section">
        {{ $t("news.form.auTokenLabel") }}
      </div>
      <div class="nf-paragraph">
        <AuSelect
          :value="news.auCapital"
          :options="progectsOptions"
          required
          clearable
          :placeholder="$t('news.form.auTokenPlaceholder')"
          @input="value => news.auCapital = value"
        />
      </div>
      <div class="nf-section">
        {{ $t("news.form.imageLabel") }}
      </div>
      <div class="nf-paragraph">
        <AuInput
          :model-value="image"
          type="File"
          required
          :uploaded-file-name="uploadedFileName"
          @update:modelValue="imageChanged"
        />
      </div>
      <div class="nf-section">
        {{ $t("news.form.titleLabel") }}
      </div>
      <div class="nf-paragraph">
        <AuInput
          v-model="news.title"
          :placeholder="$t('news.form.titlePlaceholder')"
          required
          clearable
        />
      </div>
      <div class="nf-section">
        {{ $t("news.form.subtitleLabel") }}
      </div>
      <div class="nf-paragraph">
        <AuInput
          v-model="news.subtitle"
          :placeholder="$t('news.form.subtitlePlaceholder')"
          required
          clearable
          text-area-heigth="75"
          :type="'TextArea'"
        />
      </div>
      <div class="nf-section">
        {{ $t("news.form.contentLabel") }}
      </div>
      <div class="nf-paragraph">
        <AuInput
          v-model="news.content"
          :placeholder="$t('news.form.contentPlaceholder')"
          required
          clearable
          :type="'TextArea'"
        />
      </div>
      <div class="d-flex justify-content-end">
        <div class="btns-wrapper d-flex justify-content-sm-end">
          <AuButton
            type="primary"
            @click="publishNews"
          >
            {{ $t("news.form.publishNewsButton") }}
          </AuButton>
          <AuButton
            @click="addToDraft"
          >
            {{ $t("news.form.addToDraftButton") }}
          </AuButton>
        </div>
      </div>
      <div
        v-if="reqError"
        class="req-error"
      >
        {{ $t("news.form.error") }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import ProgressLoader from "@/loaders/progress-bar";
import { v4 } from "uuid";

import { mapActions, mapGetters } from "vuex";

export default {
  components: { ProgressLoader },
  data() {
    return {
      news: {
        locale: this.$i18n.locale == "ru" ? "rus" : "eng",
        title: null,
        subtitle: null,
        content: null,
        image: null,
        auCapital: null,
        isDraft: null,
        author: `/api/users/${this.$auth.user().id}`,
        slug: v4().split("-")[0],
        dateForNews: moment().format("DD.MM.YYYY hh:mm:ss")
      },
      myCapitals: [],
      image: null,
      uploadedFileName: null,
      reqError: false,
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      blogPages: "news/blogPages",
      uploadedFile: "files/uploadedFile"
    }),

    progectsOptions() {
      return _.map(this.myCapitals, project => ({ display: project.tokenBase.nameEng, value: `/api/au_capitals/${project.id}` }));
    },

    checkFields() {
      return Object.values(this.news).length == _.filter(Object.values(this.news), value => value !== null && value !== "").length ? true : false;
    },

    newsId() {
      return this.$attrs.id;
    },

    editMode() {
      return this.newsId != "new";
    },

    label() {
      return this.editMode ? this.$t("news.form.editNews") : this.$t("news.form.createLabel");
    }
  },

  async mounted() {
    this.loading = true;
    await this.getCapital();
    if (this.editMode) {
      let newsForEdit = _.find(this.blogPages, { id: Number(this.newsId) });

      await this.getUploadedFile(newsForEdit.image.split("/")[newsForEdit.image.split("/").length - 1]);

      this.uploadedFileName = this.uploadedFile.nameOriginal;
      this.news.locale = newsForEdit.locale;
      this.news.title = newsForEdit.title;
      this.news.subtitle = newsForEdit.subtitle;
      this.news.content = newsForEdit.content;
      this.news.auCapital = newsForEdit.auCapital["@id"];
      this.news.isDraft = newsForEdit.isDraft;
      this.news.slug = newsForEdit.slug;
      this.news.dateForNews = newsForEdit.dateForNews;
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      postBlogPage: "news/postBlogPage",
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination",
      uploadFile: "files/uploadFile",
      getUploadedFile: "files/getUploadedFile",
      patchBlogPage: "news/patchBlogPage"
    }),

    imageChanged(file) {
      this.uploadedFileName = null;
      this.news.image = file;
    },
    async getCapital() {
      try {
        const resp = await this.getAllAuCapitalsPagination({
          page: 1,
          itemsPerPage: 100,
          user: this.$auth.user().id
        });
        this.myCapitals = resp.data;
      }
      catch (e) {
        console.log("error", e);
      }
    },

    async publishNews() {
      this.news.isDraft = false;
      try {
        if (!this.editMode) {
          if (this.news.image === null) {
            this.reqError == true;
          }
          else if (typeof this.news.image !== "string") {
            let file = await this.uploadFile(this.news.image);
            this.news.image = `/api/uploaded_files/${file.id}`;
          }
          if (this.checkFields) {
            this.reqError = false;
            await this.postBlogPage(this.news);

            this.$toast.success(this.$t("toasts.success.newsPublish"));
            this.$router.push({ name: "news" });
          }
          else {
            this.reqError = true;
          }
        }
        else {
          if (!this.uploadedFileName) {
            let file = await this.uploadFile(this.news.image);
            this.news.image = `/api/uploaded_files/${file.id}`;
          }
          else {
            this.news.image = `/api/uploaded_files/${this.uploadedFile.id}`;
          }
          let newsCopy = _.cloneDeep(this.news);
          newsCopy.id = Number(this.newsId);
          if (this.checkFields) {
            this.reqError = false;
            await this.patchBlogPage(newsCopy);

            this.$toast.success(this.$t("toasts.success.newsUptPublish"));
            this.$router.push({ name: "news" });
          }
          else {
            this.reqError = true;
          }
        }
      }
      catch (error) {
        this.$toast.error(this.$t("toasts.error.newsPublish"));
      }
    },

    goBack() {
      this.$router.push({ name: "news" });
    },

    async addToDraft() {
      this.news.isDraft = true;
      try {
        if (!this.editMode) {
          if (this.news.image === null) {
            this.reqError == true;
          }
          else if (typeof this.news.image !== "string") {
            let file = await this.uploadFile(this.news.image);
            this.news.image = `/api/uploaded_files/${file.id}`;
          }
          if (this.checkFields) {
            this.reqError = false;
            await this.postBlogPage(this.news);

            this.$toast.success(this.$t("toasts.success.newsDraft"));
            this.$router.push({ name: "news" });
          }
          else {
            this.reqError = true ;
          }
        }
        else {
          if (!this.uploadedFileName) {
            let file = await this.uploadFile(this.news.image);
            this.news.image = `/api/uploaded_files/${file.id}`;
          }
          else {
            this.news.image = `/api/uploaded_files/${this.uploadedFile.id}`;
          }
          let newsCopy = _.cloneDeep(this.news);
          newsCopy.id = Number(this.newsId);
          if (this.checkFields) {
            this.reqError = false;
            await this.patchBlogPage(newsCopy);

            this.$toast.success(this.$t("toasts.success.newsUptDraft"));
            this.$router.push({ name: "news" });
          }
          else {
            this.reqError = true;
          }
        }
      }
      catch (error) {
        this.$toast.error(this.$t("toasts.error.newsDraft"));
      }
    }

  }
};
</script>

<style lang="scss" scoped>
.news{
  padding: 20px;
  font-family: 'Suisse Intl',serif;
  font-style: normal;
}
.head_text {
  font-weight: 500;
  font-size: 20px;
}
.nf-section {
  font-size: 14px;
  margin-bottom: 6px;
}
.nf-paragraph{
  margin-bottom: 24px;
}
.req-error {
  display: flex;
  justify-content: center;
  color: red;
}
.btns-wrapper {
  gap: 10px
}
</style>