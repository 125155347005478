import axios from "axios";

export default class axiosClient {
  async getAllAuCapitals(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    let url = "api/au_capitals";
    if (params?.description) {
      url = "api/au_capitals/search";
    }
    const resp = await axios.get(url, { headers, params });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getInvestmentsAuCapitals(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    try {
      const resp = await axios.get("api/au_capitals/my_investments", { headers, params });
      const data = resp.data["hydra:member"];
      const totalItems = resp.data["hydra:totalItems"];
      return { data, totalItems };
    }
    catch (error) {
      console.log(error);
    }
  }

  async getAuCapitals(id) {
    try {
      const resp = await axios.get(`api/au_capitals/${id}`);
      return resp.data;
    }
    catch (error) {
      console.log(error);
    }
  }

  async getAuCapitalsByParams(data) {
    const params = {
      page: 1,
      itemsPerPage: 3,
      ...data
    };

    const resp = await axios.get("api/au_capitals", { params });
    return resp.data;
  }

  async getCapitalInvestor(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const resp = await axios.get("api/au_capital_investors/get", {
      headers: headers,
      params: params
    });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async setFollowing(id) {
    const body = {
      auCapital: id
    };
    const resp = await axios.post("/api/following_capitals/start", body);
    return resp.data;
  }

  async unsetFollowing(id) {
    const body = {
      auCapital: id
    };
    const resp = await axios.post("/api/following_capitals/stop", body);
    return resp.data;
  }

  async getFollowings() {
    const params = {
      page: 1,
      itemsPerPage: 30,
      pagination: false
    };
    const resp = await axios.get("api/following_capitals", { params });
    return resp.data;
  }

  async downloadMemorandum(name, id) {
    const response = await axios({
      url: "api/au_capital/memorandum/" + id,
      method: "GET",
      responseType: "blob"
    });

    const href = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", name + " memorandum");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
}