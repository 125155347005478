<template>
  <div class="card briefcase-horizontal">
    <div class="header-line">
      <div class="fs22">
        {{ $t("rightPanel.briefcase.label") }}
      </div>
      <div v-if="!loading">
        <span
          v-if="fullSum"
          class="fs22 amount-balance"
        >
          <AuDecimalStatic
            :value="fullSum"
            :decimal="2"
            :indent="2"
            :addition="defaultTokenMnemonic"
          />
        </span>
        <span
          v-else
          class="fs22 amount-balance"
        >0 {{ defaultTokenMnemonic }}</span>
      </div>
    </div>
    <div class="middle-line">
      <Apexchart
        v-if="!chartLoading && balance"
        :options="options"
        :series="mappedSeries"
        height="200"
      />
      <div class="no-assets">
        <Apexchart
          v-if="!chartLoading && !balance"
          :options="optionsNoAssets"
          :series="mappedSeries"
          height="200"
        />
      </div>
    </div>
    <div class="footer-line">
      <div
        class="sub-header"
      >
        <RouterLink
          :to="{ path: '/briefcase', query: { selectedTitle: 'My orders' }}"
        >
          {{ $t("briefcase.amountInvest") }}
          <span v-if="totalSumInvest">
            <AuDecimalStatic
              :value="totalSumInvest"
              :indent="2"
              :addition="defaultTokenMnemonic"
            />
          </span>
          <span v-else>
            <AuSkeleton />
          </span>
        </RouterLink>
      </div>
      <div class="button-block">
        <AuButton
          type="primary"
          center
          @click="goToDeposit"
        >
          {{ $t("briefcase.buy") }}
        </AuButton>
        <AuButton
          type="primary"
          center
          @click="goToWithdraw"
        >
          {{ $t("briefcase.withdraw") }}
        </AuButton>
        <AuButton
          type="primary"
          center
          @click="goToSend"
        >
          {{ $t("briefcase.sendAction") }}
        </AuButton>
        <!-- <AuDropdown
          v-model="paramsDropdown"
          class="dropdown-flex"
          :options="optionsDropdown"
          :placeholder="$t('briefcase.more')"
          @select="handleSelect"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      heightInPixels: "20",
      currencySup: new CurrencySupport(),
      loading: false,
      orderLoading: false,
      fullSum: 0,
      balance: 0,
      series: [],
      mappedSeries: [],
      dataOrders: [],
      totalSumInvest: null,
      width: null,
      chartLoading: false,
      defaultTokenMnemonicId: null,
      paramsDropdown: null,
      optionsDropdown: [
        { display: this.$t("briefcase.operations"), value: "operations" },
        { display: this.$t("briefcase.extract"), value: "extract" }
      ]
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      tokensDao: "tokensDao",
      tokensUtility: "tokensUtility",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      exchangeBalances: "exchangeBalances",
      defaultTokenEnv: "defaultToken/defaultTokenEnv",
      checkProfile: "profile/checkProfile"
    }),
    cssVars() {
      return {
        "--scroll": this.labels?.length < 9 ? "hidden" : "scroll"
      };
    },

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    colorLabel() {
      return this.isLightTheme
        ? Array(9).fill("#080808")
        : Array(9).fill("#fff");
    },

    labels() {
      let resp = [];
      this.series.forEach((series) => {
        const owner = _.find(
          this.auOwners,
          (owner) => owner.auToken.id == series.id
        );

        if (owner.auToken.mnemonicEng == this.defaultTokenEnv) {
          resp.push(owner.auToken.mnemonicEng);
        }
        else {
          resp.push(
            `<span class="legend">${
              this.defaultTokenEnv
            }: <b>${this.convertNumber(
              this.getPrice(owner).mainTokenPrice
            )}</b><br/></span>${owner.auToken.mnemonicEng}`
          );
        }
      });
      if (!resp.length) {
        resp = [];
        resp.push(this.$t("rightPanel.briefcase.noAssets"));
      }
      return resp;
    },

    options: {
      get: function () {
        return {
          labels: this.labels,
          chart: {
            type: "donut",
            show: false,
            hidden: true
          },
          pie: {
            expandOnClick: false,
            donut: {
              background: "transparent",
              labels: {
                show: false,
                total: {
                  showAlways: false,
                  show: false
                }
              }
            }
          },
          colors: [
            "#299667",
            "#9720F6",
            "#E2A64D",
            "#489EE1",
            "#294896",
            "#35d5ec",
            "#E2684D",
            "#489EE1",
            "#C3B3D0"
          ],
          dataLabels: {
            enabled: false
          },
          tooltip: {
            enabled: true,
            y: {
              formatter(value) {
                if (value == 0) {
                  return 0;
                }

                value = Math.round(value * 100) / 100;

                let formattedPrice;
                if (value % 1 === 0) {
                  formattedPrice = value
                    .toLocaleString("ru-RU")
                    .replace(",", ".");
                }
                else {
                  formattedPrice = value
                    .toLocaleString("ru-RU", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                    .replace(",", ".");
                }
                return formattedPrice;
              }
            }
          },
          legend: {
            show: true,
            position: "right",
            height: this.heightInPixels,
            fontSize: "10px",
            fontFamily: "Suisse Intl",
            labels: {
              colors: this.colorLabel
            },
            markers: {
              width: 8,
              height: 8
            }
          }
        };
      },
      set: function (newValue) {
        return newValue;
      }
    },
    optionsNoAssets: {
      get: function () {
        return {
          labels: this.labels,
          chart: {
            type: "donut",
            show: false,
            hidden: true
          },
          pie: {
            expandOnClick: false,
            donut: {
              background: "transparent",
              labels: {
                show: false,
                total: {
                  showAlways: false,
                  show: false
                }
              }
            }
          },
          colors: [
            "#c6c6c6"
          ],
          dataLabels: {
            enabled: false
          },
          tooltip: {
            enabled: false
          },
          legend: {
            show: true,
            position: "right",
            height: "20",
            fontSize: "10px",
            fontFamily: "Suisse Intl",
            labels: {
              colors: this.colorLabel
            },
            markers: {
              width: 8,
              height: 8
            }
          }
        };
      },
      set: function (newValue) {
        return newValue;
      }
    }
  },

  watch: {
    auOwners: {
      immediate: true,
      handler() {
        this.checkBalance(this.auOwners);
        this.checkTokenMnemonicId(this.auOwners);
      }
    },
    defaultTokenMnemonic: {
      immediate: true,
      handler() {
        this.checkBalance(this.auOwners);
        this.checkTokenMnemonicId(this.auOwners);
      }
    },
    checkProfile: {
      immediate: true,
      async handler() {
        await this.getAuOwners();
      }
    }
  },

  async mounted() {
    window.addEventListener("resize", this.resizeHandler);
    window.addEventListener("resize", this.legendWidth);
    this.$nextTick(() => {
      this.legendWidth();
    });
    await this.getOrdersPage();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.legendWidth);
    window.removeEventListener("resize", this.resizeHandler);
  },


  methods: {
    ...mapActions({
      getOwnerPage: "getOwnerPage",
      getAuOwners: "getAuOwners",
      getWithdrawalRequests: "withdrawal/getWithdrawalRequests",
      getInvestmentsAuCapitals: "capitals/getInvestmentsAuCapitals"
    }),

    handleSelect(selectedOption) {
      console.log("selectedOption", selectedOption);
    },

    goToDeposit() {
      this.$router.push({ name: "buy form" });
    },

    goToWithdraw() {
      this.$router.push({ name: "withdraw" });
    },

    goToSend() {
      this.$router.push({
        name: "send token",
        params: { id: this.defaultTokenMnemonicId }
      });
    },

    async changeType(type) {
      this.type = type;
    },

    resizeHandler() {
      this.chartLoading = true;
      this.$nextTick(() => {
        this.chartLoading = false;
      });
    },
    legendWidth() {
      const legend = document.querySelector(".apexcharts-legend");
      if (legend) {
        const parentWidth = legend.parentElement.clientWidth;
        const widthPixels = (parentWidth * 0.7);

        if (widthPixels < 230) {
          this.heightInPixels = "40";
        }
        else {
          if (this.series.length < 4) {
            this.heightInPixels = "20";
          }
          if (this.series.length > 4 && this.series.length < 7) {
            this.heightInPixels = "40";
          }
          if (this.series.length > 7) {
            this.heightInPixels = "80";
          }
        }
      }
    },
    changeChart(chartContext, _config) {
      this.$nextTick(() => {
        chartContext.pie.centerX = 75;
      });
    },

    getPrice(owner) {
      let price = this.currencySup.getCurrency(
        owner.auToken.id,
        this.defaultTokenMnemonic
      );
      let amount = this.currencySup.getBalance(owner.auToken.id);

      price = BigNumber(price).multipliedBy(owner.amount);
      price = price
        .dividedBy(BigNumber(10).pow(owner.auToken.decimals))
        .toNumber();

      let mainTokenPrice = BigNumber(amount)
        .dividedBy(BigNumber(10).pow(owner.auToken.decimals))
        .toFixed(owner.auToken.indent);

      return (price = {
        mainTokenPrice: Number(mainTokenPrice),
        tokenPrice: Number.isNaN(price) ? 0 : price
      });
    },

    convertNumber(price) {
      if (price == 0) {
        return 0;
      }

      price = Math.round(price * 100) / 100;

      let formattedPrice;
      if (price % 1 === 0) {
        formattedPrice = price.toLocaleString("ru-RU").replace(",", ".");
      }
      else {
        formattedPrice = price
          .toLocaleString("ru-RU", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
          .replace(",", ".");
      }
      return formattedPrice;
    },

    checkTokenMnemonicId(value) {
      const found = _.find(
        value,
        (item) =>
          _.get(item, "auToken.mnemonicEng") == this.defaultTokenMnemonic
      );

      this.defaultTokenMnemonicId = found?.auToken?.id;
    },
    checkBalance(value) {
      this.series = [];
      this.mappedSeries = [];

      _.forEach(value, (owner) => {
        let price = this.getPrice(owner).tokenPrice;

        const isFloor = Math.floor(price) == price;
        const finalPrice = isFloor ? Math.floor(price) : price;

        this.series.push({ id: owner.auToken.id, price: finalPrice });

        this.mappedSeries.push(finalPrice);
      });

      this.balance = _.sum(this.mappedSeries);
      this.fullSum = _.sum(this.mappedSeries);

      if (!this.fullSum) {
        this.mappedSeries = [];
        this.mappedSeries.push(1);
      }
    },

    async getOrdersPage() {
      this.orderLoading = true;
      try {
        let resp = await this.getInvestmentsAuCapitals({
          pageId: 1,
          status: "open"
        });

        this.dataOrders = resp.data;
        this.getSumInvest(this.dataOrders);
      }
      catch (e) {
        console.log("error", e);
      }
      finally {
        this.orderLoading = false;
      }
    },
    async getSumInvest(arr) {
      const result = _.map(arr, (item) => {
        let sum;
        if (item?.tokenQuote?.mnemonicEng == this.defaultTokenMnemonic) {
          sum = BigNumber(item.sum).dividedBy(BigNumber(10).pow(item.tokenQuote.decimals)).toFixed(item.tokenQuote.decimals);
        }
        else {
          const price = this.currencySup.getCurrency(item?.tokenQuote?.id, this.defaultTokenMnemonic);
          sum = (BigNumber(item.sum).dividedBy(BigNumber(10).pow(item.tokenQuote.decimals)).toFixed(item.tokenQuote.decimals)) * price;
        }
        return Number(sum);
      });
      this.totalSumInvest = String(_.sum(result));
    }
  }
};
</script>

<style lang="scss">
.briefcase-horizontal {
  display: flex;
  flex-direction: column;
  .middle-line {
    .vue-apexcharts {
      .apexcharts-canvas {
        .apexcharts-svg {
          foreignObject {
            .apexcharts-legend.apx-legend-position-right {
              right: 0 !important;
              top: 50% !important;
              transform: translateY(-50%);

              right: 0 !important;
              top: 50% !important;
              transform: translateY(-50%);

              width: 70%;
              height: 40px;
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
              gap: 1px 0;
              grid-auto-rows: 18px;
              // overflow-y: var(--scroll);
              overflow-x: hidden;

              // альтернатива
              // display: flex;
              // flex-direction: row;
              // justify-content: start;
              // align-items: center;
              // align-self: center;
              // flex-wrap: wrap;
              // max-width: 70%;
              // height: 60px;
              // padding-left: 10px;
              // margin-bottom: 10px;
              .apexcharts-legend-series {
                display: flex;
                align-items: center;
                .apexcharts-legend-marker {
                  height: 8px;
                  width: 8px;
                  margin-bottom: 1px;
                  flex-shrink: 0;
                  svg {
                    border-radius: 50%;
                  }
                }
                .apexcharts-legend-text {
                  display: flex;
                  align-items: center;
                  padding-left: 2px;
                  margin-left: 0px;
                  .legend {
                    display: none;
                  }
                }
              }
            }
          }
          g.apexcharts-inner.apexcharts-graphical {
            display: contents;
            g {
              g {
                g {
                  g {
                    path {
                      stroke: var(--card-color);
                      stroke-width: 1px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.briefcase-horizontal {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
}
.briefcase-horizontal .header-line,
.briefcase-horizontal .middle-line,
.briefcase-horizontal .footer-line {
  height: 100%;
}

.header-line {
  display: flex;
  padding: 15px;
  justify-content: space-between;
}
.amount-balance {
  color: #5ac396;
}
.middle-line {
  padding: 0 15px;
}
.footer-line {
  padding: 15px;
  a {
    color: var(--color-00);
    text-decoration: none;
    display: flex;
    align-items: center;
    span {
      margin-left: 2px;
    }
  }
}
.button-block {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
  gap: 6px;
}
.dropdown-flex {
  flex-grow: 1;
  min-width: 0;
  max-width: 150px;
}
</style>
