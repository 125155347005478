<template>
  <div>
    <AuTooltip
      v-if="tooltipText.length > 0"
      type="slot"
      :description="tooltipText"
    >
      <img
        class="token-picture"
        :src="imgSrc"
        alt="token icon"
      >
    </AuTooltip>
    <img
      v-else
      class="token-picture"
      :src="imgSrc"
      alt="token icon"
    >
    <AuDecimalStatic
      :value="value"
      :decimal="decimal"
      :indent="indent"
      :show-addition="false"
    />
  </div>
</template>

<script>
export default {
  name: "AuDecimalIcon",
  props: {
    imgSrc: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    decimal: {
      type: String,
      default: ""
    },
    indent: {
      type: String,
      default: ""
    },
    tooltipText: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.token-picture {
  width: 30px;
  height: 30px;
}
</style>